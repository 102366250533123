.text_shadow {

    background: #fdfdfd;
    margin-left: auto;
    margin-right: auto;
    bottom:15px;
    display: block;
    border-radius: 20px;
    box-shadow: 0px 3px 20px -11px rgb(0 0 0 / 55%);
    -webkit-box-shadow: 0px 3px 20px -11px rgb(0 0 0 / 55%);
    -moz-box-shadow: 0px 3px 20px -11px rgb(0 0 0 / 55%);

}

.text_typography {
    font-family: 'Montserrat', sans-serif !important;
    color: #565656;
    padding: 20px;
    text-overflow: ellipsis;
}