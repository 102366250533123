.searchInputs {
  display: flex;
  z-index: 1;
}

 /* .search input {
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 20px;
  height: 20px;
} */

/* .searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}  */

input:focus {
  outline: none;
}

.dataResult {
  margin-top: 5px;
  cursor: pointer;
  width: 240px;
  height: 300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  position: absolute !important;
}



.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

.dataItem:hover{
  color: #0066b3;
}

#clearBtn {
  cursor: pointer;
}