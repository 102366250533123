.vl {
    font-size: 8vw;
    border-left: 1px solid black;
}
.colrbck{
    background-color: #f3f3f3;
}



.dd {
    width: 180px;
    line-height: 40px!important;
    margin-top: 15px;
    padding-top: 4px;
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
    text-align: center;
    float: left;
    margin-top: 19px;
    border-radius: 2px;
    color: #fff !important;
    background: #0066b3 !important;
    text-decoration: none;
}

.dd:hover{
    background: #ccc!important;
    color: #0066b3!important;
    text-decoration: none;
    /* font-weight: 550 !important; */
    
}

.Typography_text{
    font-size:27px;
    font-weight: 500;
    min-width: 1vw;
}
.Typography_year{
    font-size:20px;
}