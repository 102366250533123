.singleBox {
  text-align: center;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #0066b3;
  margin: 0 20px;
  transition: 1s;
}
.singleBox img {
  max-height: 60px;
  width: auto;
}
.boxContent {
  font-size: 18px;
  padding-top: 10px;
  font-weight: 600 !important;
  text-decoration: none;
  line-height: 1.3;
  transition: 0.5s;
}
.singleBox:hover {
  transform: scale(1.05);
  box-shadow: 0px 0 10px rgb(0 0 0 / 30%);
}

.Link_hover_Style {
  color: #0066b3;
  text-decoration: none;   
}
.Link_hover_Style:hover {
  color: #0066b3;
  text-decoration: none;   
}
