.btncolrhov {
    width: 130px;
    line-height: 30px!important;
    margin-top: 15px;
    padding-top: 4px;
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
    text-align: center;
    float: left;
    margin-top: 19px;
    border-radius: 2px;
    color: #0066b3 !important;
    background: transparent!important;
    border: 1px #0066b3 solid !important;
    text-decoration: none;
}

.btncolrhov:hover ,.navactive{
    width: 130px;
    line-height: 30px!important;
    margin-top: 15px;
    padding-top: 4px;
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
    text-align: center;
    float: left;
    margin-top: 19px;
    border-radius: 2px;
    background: #f5f0ea!important;
    color: #0066b3!important;
    text-decoration: none;
    border: none !important;
    outline: none !important;
    /* font-weight: 550 !important; */
}



.btncolaf {
    background: #f5f0ea!important;
    color: #0066b3!important;
    text-decoration: none;
    /* font-weight: 550 !important; */
}

.carddivslider {
    height: 150px;
    width: 95% !important;
    position: relative;
    border-radius: 20px;
    border: 1px solid rgb(204, 197, 197);
}

/* .slick-slide-affiliates>div>div>div {
    padding: 5px !important;
    text-align: center !important;
    margin-right: 3px !important;
    margin-left: 2px !important;
} */

.affliates_spacing {
    height: 220px;
    top: 30px;
}

.img_position_set {
    position: absolute;
} 


.imag_style{
    width: 50%;
    height: 50%;
    margin:30px ;
    
}



