.linkhov:hover{
        color:#0066b3 !important;
        text-decoration: none !important;
        transition:ease-in  0.7s !important;
}
.Typograpy_Text_h1{
    font-weight: 500 !important;
    text-transform: capitalize;
}
.Typograpy_Text_h4{
    font-size: 18px;
    font-weight: 500 !important;
    text-transform: uppercase;
}


@media (max-width: 575.98px) 
    
 {
   .Typograpy_Text_h1{
        font-size: 40px !important;
    }
    .Typograpy_Text_h4{
        font-size: 12px !important;
    }

}
@media (max-width: 400px) 
{
    
    .Typograpy_Text_h1{
        font-size: 35px !important;
    }
    .Typograpy_Text_h4{
        font-size: 12px !important;
    }

}
