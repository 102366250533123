.evenspace{
    display: flex;
    justify-content:space-between;
 }

.maxminHeight{
    max-height: '170px';
}



@media (max-width: 570px) 
{
  .custome_xs_screen{
    display:block !important;
  }
  
}

/* @media (min-width: 1200px) {
  .container {
     max-width: 1200px;
   }
}
@media screen and (min-width: 1800px) {
  .container {
    max-width: 1800px !important;
  }
} */
@media screen and (min-width: 2600px) {
  .container {
    max-width: 2800px !important;
  }
}
@media (min-width: 1400px) {
  .container {
     max-width: 1800px !important;
   }
}
@media screen and (min-width: 1800px) {
  .container {
    max-width: 1800px !important;
  }
}
