.navbar-customgrid{
    background: #EFFBEF;
    grid-area: nav;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem !important;
}
.nav_icon_custom {
    display: none;
  }
  
  .nav_icon_custom > i {
    font-size: 26px;
    color: #a5aaad;
  }
  
  
  .navbar__left .active_link {
    color: #265acc;
    border-bottom: 3px solid #265acc;
    padding-bottom: 12px;
  }
  
  .navbar__right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar__right > a {
    margin-left: 20px;
    text-decoration: none;
  }
  
  .navbar__right > a > i {
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
    padding: 7px;
  }
  
  @media only screen and (max-width: 978px) {
    .nav_icon {
      display: inline;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .navbar__right {
      display: none;
    }
   
  }
  