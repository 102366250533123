
.dd {
    width: 130px !important;
    height: 39px !important;
    margin-top: 15px !important;
    padding-top: 0px !important;
    font-size: 14px !important;
    padding-bottom: 5px !important;
    display: block !important;
    text-align: center !important;
    float: left !important;
    margin-top: 19px !important;
    border-radius: 2px!important;
    color: #fff !important;
    background: #0066b3 !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
}

.dd:hover{
    background: #ccc!important;
    color: #0066b3!important;
    text-decoration: none;
    /* font-weight: 550 !important; */

}

@media (max-width: 768px) { 
   
    .smallscr_responsive{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .imgmargin{
        margin-bottom: 20px !important;
    }
   
 }

