.flexbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

nav{
    position: relative;
}

.hoverdropdown 
{
    font-weight: 600;
}

.secnav:hover {
    
    color: #337ab7 !important; 
    }
.firstnav:hover {
    color: #ea4a34 !important; 
    }



.navmenu_custome_li:hover {
    color: #0066b3 !important;
    background-color: transparent !important;
}
/* .navmenu_custome_li:focus{
    color: #fff !important;
    background-color: #0066b3 !important;
} */
 /* .navmenu_custome:focus,  */
.navmenu_custome_li:hover {
    color: #fff !important;
    background-color: #0066b3 !important;
} 


.navmenu_custome {
 
    padding: 0.8rem 1rem !important;
    width: 270px;
    margin: 15px 0px 0 0px;
    padding: 8px 0px 8px;
    margin-left: 10px;
    text-align: left;
    list-style: none;
    box-shadow: 0 0px 3px rgb(0 0 0 / 15%);
}

.navmenu_custome_li {
    padding: .8rem 1rem !important;
    border-radius: 4px !important;
    height: 40px;
    padding: 0;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    text-align: start;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: 'uppercase' !important;
    letter-spacing: 0.5px !important;
}


.secnav {
        color: rgba(0,0,0,.55) !important; 
        }
.firstnav{
        color: rgba(0,0,0,.55) !important; 
        }


.secnav::after {
    display: none !important;
}
.firstnav::after {
    display: none !important;
}

.dropdown:hover .dropdown-menu {
    display: block !important;
 }
 .dropdown-menu{
     text-transform: uppercase;
     
 }
 .dropdown .dropdown-menu{ 
     transition:ease 1s !important;
     
  }

  .disablehover .disablehover-menu{
    display: none !important;
  }
  .disablehover .enablehover-menu{
    display: block !important;
 
  }


 .conCapitalized{
     text-transform: capitalize !important;
 }
 .fontsizefamily{
    font-family: 'Monsterrat' !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    text-transform: uppercase;
    transition: none;
    padding: 5px 10px;
    letter-spacing: 0.5px !important;
 }
 .custom-nav{
    box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);    
 }
 .form-control:focus {
    color: black !important;
    background-color: none !important;
    border-color: rgb(161, 161, 161) !important;
    outline: 0;
    box-shadow: none !important;
}
.navbar-nav .dropdown-menu {
    font-family: 'Monsterrat' !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color:#337ab7 !important;
}
p, a, span, h1, h2, h3, h4, h5, h6, div ,li{
    font-family: 'Montserrat', sans-serif !important;
    
  }
  
