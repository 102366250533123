#sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px whitesmoke;
  border-radius: 10px;
}

#sidebar::-webkit-scrollbar-thumb {
  background: whitesmoke;
  border-radius: 10px;
}


#sidebar {
  background: #FFF;
  grid-area: sidebar;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height:100%
  /* position: relative; */

}

.sidebar__title {
  display: flex;
  justify-content: center;
  color: #f3f4f6;
  margin-bottom: 10px;
  margin-top: 20px;
  /* color: #E85B6B; */
}
.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px ;
  font-weight: 700;
}

.sidebar__link {
  text-decoration : none ;
  list-style:none;
  width:100%;
  padding : 0.5rem 0 ;
  cursor : pointer ;
  display: flex;
  padding-left: 1rem; 
}







/* .sidebar__logout {
  position: absolute;
  bottom: 0;
  border : 1px solid red !important;

} */




.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}
